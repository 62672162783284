export default ({ $config, store }, inject) => {
  const pageType = () => 'locationssearch';
  const { host } = window.location;
  function nginxDecodeUserId(nginxUserIdCookie) {
    const hex = window
      .atob(nginxUserIdCookie)
      .split('')
      .map((aChar) => `0${aChar.charCodeAt(0).toString(16)}`.slice(-2))
      .join('')
      .toUpperCase();
    let hexConstructed = '';
    for (let i = 0; i < hex.length; i += 8) {
      const dword = hex.substring(i, i + 8);
      const hexed = Number(`0x${dword.match(/../g)?.reverse().join('')}`)
        .toString(16)
        .padStart(8, '0')
        .toUpperCase();
      hexConstructed += hexed;
    }
    return hexConstructed;
  }

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return undefined;
  }

  const visitorIdCookie = getCookie('uid');
  if (!visitorIdCookie) {
    console.log('[FLAGSHIP GTM] VisitorId is not defined');
    return;
  }
  const visitorId = nginxDecodeUserId(visitorIdCookie);
  store.commit('experiments/setVisitorId', visitorId);
  const flagshipEnvironmentId = getCookie('xpe');
  if (!flagshipEnvironmentId) {
    console.log('[FLAGSHIP GTM] environment ID is not defined');
    return;
  }
  store.commit('experiments/setEnvironmentId', flagshipEnvironmentId);
  const xpc = getCookie('xpc');
  if (!xpc) {
    console.warn('XPC cookie is missing');
    return;
  }
  let parsedXPC = {};
  try {
    parsedXPC = JSON.parse(atob(xpc));
  } catch (ex) {
    console.error('Error parsing XPC cookie', ex);
    return;
  }
  parsedXPC?.campaigns?.forEach((campaign) => {
    if (!campaign) {
      return;
    }
    const campaignId = campaign.id;
    const { variationGroupId } = campaign;
    const variationId = campaign.variation?.id;
    const flags = campaign.variation?.modifications?.value;
    if (!flags) {
      return;
    }
    const [flag] = Object.keys(flags).filter((e) => !e.startsWith('application-')); // to filter out to app targeting flags
    if (!flag) {
      return;
    }
    store.commit('experiments/setVariation', {
      campaignId,
      variationGroupId,
      variationId,
      flag,
    });
  });
  const flagshipDecisionAPIEndpoint = $config.FLAGSHIP_DECISION_API_BASE_URL;
  const flagshipEventsAPIEndpoint = $config.FLAGSHIP_EVENTS_API_BASE_URL;

  window.dataLayer = window.dataLayer || [];

  async function sendDatalayerEvent(experimentId, variationId) {
    return new Promise((resolve) => {
      window.dataLayer.push({
        event: 'experiment_data',
        dl_experiment_id: experimentId,
        dl_experiment_variation: `${experimentId}:${variationId}`,
        dl_experiment_user_id: visitorId,
      });
      resolve();
    });
  }

  async function expose(_visitorId, environmentId, variationGroupId, variationId, campaignId, flag) {
    sendDatalayerEvent(campaignId, variationId);
    const response = await fetch(
      `${flagshipDecisionAPIEndpoint}/activate?host=${host}&app=${pageType()}&flag=${encodeURI(flag)}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          vid: _visitorId,
          cid: environmentId,
          caid: variationGroupId,
          vaid: variationId,
        }),
      },
    );
    if (response.status !== 204) {
      console.error(`[FLAGSHIP GTM] Visitor could not be exposed, HTTP ${response.status}`);
    }
  }

  async function registerPageView() {
    const response = await fetch(`${flagshipEventsAPIEndpoint}?host=${host}&app=${pageType()}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cid: flagshipEnvironmentId,
        vid: visitorId,
        dl: document.location.href,
        ds: 'APP',
        t: 'PAGEVIEW',
      }),
    });
    if (response.status !== 200) {
      console.error(`[FLAGSHIP GTM] Pageview could not be registered, HTTP ${response.status}`);
    }
  }

  function constructObject(rawValue, keyIsValue, valueKey, obj = {}) {
    const values = rawValue.split(',');
    values.forEach((e) => {
      const [k, v] = e.split('=');
      const key = keyIsValue ? v : k;
      const value = keyIsValue ? k : v;
      obj[key] = obj[key] || {};
      obj[key][valueKey] = value;
    });
    return obj;
  }

  inject('experimentExpose', expose);
  inject('flagshipEnvironmentId', flagshipEnvironmentId);

  function handleFlagship() {
    const campaignHits = [];
    try {
      const experiments = getCookie('xps');
      const variationGroups = getCookie('xpv');
      if (experiments && variationGroups) {
        const parsedExperiments = constructObject(experiments, false, 'variationId');
        const parsedVariations = constructObject(variationGroups, true, 'variationGroupId');
        Object.keys(parsedExperiments).forEach((e) => {
          parsedExperiments[e].variationGroupId = parsedVariations[parsedExperiments[e].variationId].variationGroupId;
        });
      }
    } catch (_) {}
    Promise.allSettled(campaignHits).finally(async () => {
      await registerPageView(); // register pageview without any condition
    });
  }
  handleFlagship();
};
