export const FaqCard = () => import('../../components/global/Faq/Card.vue' /* webpackChunkName: "components/faq-card" */).then(c => wrapFunctional(c.default || c))
export const Faq = () => import('../../components/global/Faq/Faq.vue' /* webpackChunkName: "components/faq" */).then(c => wrapFunctional(c.default || c))
export const SupportCallUs = () => import('../../components/global/Support/CallUs.vue' /* webpackChunkName: "components/support-call-us" */).then(c => wrapFunctional(c.default || c))
export const SupportMailUs = () => import('../../components/global/Support/MailUs.vue' /* webpackChunkName: "components/support-mail-us" */).then(c => wrapFunctional(c.default || c))
export const SupportOpeningHours = () => import('../../components/global/Support/OpeningHours.vue' /* webpackChunkName: "components/support-opening-hours" */).then(c => wrapFunctional(c.default || c))
export const Support = () => import('../../components/global/Support/Support.vue' /* webpackChunkName: "components/support" */).then(c => wrapFunctional(c.default || c))
export const LayoutDefaultStickyNavigationBar = () => import('../../components/global/Layout/Default/StickyNavigationBar.vue' /* webpackChunkName: "components/layout-default-sticky-navigation-bar" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumbs = () => import('../../components/BreadCrumbs.vue' /* webpackChunkName: "components/bread-crumbs" */).then(c => wrapFunctional(c.default || c))
export const Divider = () => import('../../components/Divider.vue' /* webpackChunkName: "components/divider" */).then(c => wrapFunctional(c.default || c))
export const EmptyList = () => import('../../components/EmptyList.vue' /* webpackChunkName: "components/empty-list" */).then(c => wrapFunctional(c.default || c))
export const GoogleTagManager = () => import('../../components/GoogleTagManager.vue' /* webpackChunkName: "components/google-tag-manager" */).then(c => wrapFunctional(c.default || c))
export const Highlight = () => import('../../components/Highlight.vue' /* webpackChunkName: "components/highlight" */).then(c => wrapFunctional(c.default || c))
export const Logo = () => import('../../components/Logo.vue' /* webpackChunkName: "components/logo" */).then(c => wrapFunctional(c.default || c))
export const PageTemplate = () => import('../../components/PageTemplate.vue' /* webpackChunkName: "components/page-template" */).then(c => wrapFunctional(c.default || c))
export const ReferralCashback = () => import('../../components/ReferralCashback.vue' /* webpackChunkName: "components/referral-cashback" */).then(c => wrapFunctional(c.default || c))
export const Socials = () => import('../../components/Socials.vue' /* webpackChunkName: "components/socials" */).then(c => wrapFunctional(c.default || c))
export const AirportToggleInfo = () => import('../../components/Airport/ToggleInfo.vue' /* webpackChunkName: "components/airport-toggle-info" */).then(c => wrapFunctional(c.default || c))
export const CoreButton = () => import('../../components/Core/Button.vue' /* webpackChunkName: "components/core-button" */).then(c => wrapFunctional(c.default || c))
export const PagesInvoices = () => import('../../components/Pages/Invoices.vue' /* webpackChunkName: "components/pages-invoices" */).then(c => wrapFunctional(c.default || c))
export const PagesOverview = () => import('../../components/Pages/Overview.vue' /* webpackChunkName: "components/pages-overview" */).then(c => wrapFunctional(c.default || c))
export const PagesReceipts = () => import('../../components/Pages/Receipts.vue' /* webpackChunkName: "components/pages-receipts" */).then(c => wrapFunctional(c.default || c))
export const PagesVouchers = () => import('../../components/Pages/Vouchers.vue' /* webpackChunkName: "components/pages-vouchers" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsInvoiceCard = () => import('../../components/Skeletons/InvoiceCard.vue' /* webpackChunkName: "components/skeletons-invoice-card" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsReservationCard = () => import('../../components/Skeletons/ReservationCard.vue' /* webpackChunkName: "components/skeletons-reservation-card" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsReservationDetailsCard = () => import('../../components/Skeletons/ReservationDetailsCard.vue' /* webpackChunkName: "components/skeletons-reservation-details-card" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsReviewCard = () => import('../../components/Skeletons/ReviewCard.vue' /* webpackChunkName: "components/skeletons-review-card" */).then(c => wrapFunctional(c.default || c))
export const SkeletonsReviewsFrom = () => import('../../components/Skeletons/ReviewsFrom.vue' /* webpackChunkName: "components/skeletons-reviews-from" */).then(c => wrapFunctional(c.default || c))
export const ExperimentsExperimentToggle = () => import('../../components/experiments/ExperimentToggle.vue' /* webpackChunkName: "components/experiments-experiment-toggle" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const HeaderDropdown = () => import('../../components/header/Dropdown.vue' /* webpackChunkName: "components/header-dropdown" */).then(c => wrapFunctional(c.default || c))
export const HeaderFooter = () => import('../../components/header/Footer.vue' /* webpackChunkName: "components/header-footer" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/header/Header.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const HeaderLanguages = () => import('../../components/header/Languages.vue' /* webpackChunkName: "components/header-languages" */).then(c => wrapFunctional(c.default || c))
export const HeaderMegaMenu = () => import('../../components/header/MegaMenu.vue' /* webpackChunkName: "components/header-mega-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobileLanguages = () => import('../../components/header/MobileLanguages.vue' /* webpackChunkName: "components/header-mobile-languages" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobileUserPopup = () => import('../../components/header/MobileUserPopup.vue' /* webpackChunkName: "components/header-mobile-user-popup" */).then(c => wrapFunctional(c.default || c))
export const HeaderMyAccountPopup = () => import('../../components/header/MyAccountPopup.vue' /* webpackChunkName: "components/header-my-account-popup" */).then(c => wrapFunctional(c.default || c))
export const HeaderPartnerLogo = () => import('../../components/header/PartnerLogo.vue' /* webpackChunkName: "components/header-partner-logo" */).then(c => wrapFunctional(c.default || c))
export const HeaderSearchInput = () => import('../../components/header/SearchInput.vue' /* webpackChunkName: "components/header-search-input" */).then(c => wrapFunctional(c.default || c))
export const HeaderToggleMenu = () => import('../../components/header/ToggleMenu.vue' /* webpackChunkName: "components/header-toggle-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderTooltip = () => import('../../components/header/Tooltip.vue' /* webpackChunkName: "components/header-tooltip" */).then(c => wrapFunctional(c.default || c))
export const HeaderWorldMapParkos = () => import('../../components/header/WorldMapParkos.vue' /* webpackChunkName: "components/header-world-map-parkos" */).then(c => wrapFunctional(c.default || c))
export const IconsFlag = () => import('../../components/icons/Flag.vue' /* webpackChunkName: "components/icons-flag" */).then(c => wrapFunctional(c.default || c))
export const IconsIcon = () => import('../../components/icons/Icon.vue' /* webpackChunkName: "components/icons-icon" */).then(c => wrapFunctional(c.default || c))
export const IconsIconAirplane = () => import('../../components/icons/IconAirplane.vue' /* webpackChunkName: "components/icons-icon-airplane" */).then(c => wrapFunctional(c.default || c))
export const IconsIconAlert = () => import('../../components/icons/IconAlert.vue' /* webpackChunkName: "components/icons-icon-alert" */).then(c => wrapFunctional(c.default || c))
export const IconsIconBoard = () => import('../../components/icons/IconBoard.vue' /* webpackChunkName: "components/icons-icon-board" */).then(c => wrapFunctional(c.default || c))
export const IconsIconBoardThin = () => import('../../components/icons/IconBoardThin.vue' /* webpackChunkName: "components/icons-icon-board-thin" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCaretDown = () => import('../../components/icons/IconCaretDown.vue' /* webpackChunkName: "components/icons-icon-caret-down" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCaretRight = () => import('../../components/icons/IconCaretRight.vue' /* webpackChunkName: "components/icons-icon-caret-right" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCash = () => import('../../components/icons/IconCash.vue' /* webpackChunkName: "components/icons-icon-cash" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCheckmark = () => import('../../components/icons/IconCheckmark.vue' /* webpackChunkName: "components/icons-icon-checkmark" */).then(c => wrapFunctional(c.default || c))
export const IconsIconChevronDown = () => import('../../components/icons/IconChevronDown.vue' /* webpackChunkName: "components/icons-icon-chevron-down" */).then(c => wrapFunctional(c.default || c))
export const IconsIconChevronLeft = () => import('../../components/icons/IconChevronLeft.vue' /* webpackChunkName: "components/icons-icon-chevron-left" */).then(c => wrapFunctional(c.default || c))
export const IconsIconChevronRight = () => import('../../components/icons/IconChevronRight.vue' /* webpackChunkName: "components/icons-icon-chevron-right" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCommentProcessingOutline = () => import('../../components/icons/IconCommentProcessingOutline.vue' /* webpackChunkName: "components/icons-icon-comment-processing-outline" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCopy = () => import('../../components/icons/IconCopy.vue' /* webpackChunkName: "components/icons-icon-copy" */).then(c => wrapFunctional(c.default || c))
export const IconsIconCross = () => import('../../components/icons/IconCross.vue' /* webpackChunkName: "components/icons-icon-cross" */).then(c => wrapFunctional(c.default || c))
export const IconsIconDownload = () => import('../../components/icons/IconDownload.vue' /* webpackChunkName: "components/icons-icon-download" */).then(c => wrapFunctional(c.default || c))
export const IconsIconDraft = () => import('../../components/icons/IconDraft.vue' /* webpackChunkName: "components/icons-icon-draft" */).then(c => wrapFunctional(c.default || c))
export const IconsIconEyeOutlined = () => import('../../components/icons/IconEyeOutlined.vue' /* webpackChunkName: "components/icons-icon-eye-outlined" */).then(c => wrapFunctional(c.default || c))
export const IconsIconFacebook = () => import('../../components/icons/IconFacebook.vue' /* webpackChunkName: "components/icons-icon-facebook" */).then(c => wrapFunctional(c.default || c))
export const IconsIconFileAccountOutline = () => import('../../components/icons/IconFileAccountOutline.vue' /* webpackChunkName: "components/icons-icon-file-account-outline" */).then(c => wrapFunctional(c.default || c))
export const IconsIconFullStar = () => import('../../components/icons/IconFullStar.vue' /* webpackChunkName: "components/icons-icon-full-star" */).then(c => wrapFunctional(c.default || c))
export const IconsIconInfiniteLoading = () => import('../../components/icons/IconInfiniteLoading.vue' /* webpackChunkName: "components/icons-icon-infinite-loading" */).then(c => wrapFunctional(c.default || c))
export const IconsIconInfo = () => import('../../components/icons/IconInfo.vue' /* webpackChunkName: "components/icons-icon-info" */).then(c => wrapFunctional(c.default || c))
export const IconsIconInstagram = () => import('../../components/icons/IconInstagram.vue' /* webpackChunkName: "components/icons-icon-instagram" */).then(c => wrapFunctional(c.default || c))
export const IconsIconLoad = () => import('../../components/icons/IconLoad.vue' /* webpackChunkName: "components/icons-icon-load" */).then(c => wrapFunctional(c.default || c))
export const IconsIconLock = () => import('../../components/icons/IconLock.vue' /* webpackChunkName: "components/icons-icon-lock" */).then(c => wrapFunctional(c.default || c))
export const IconsIconLockThin = () => import('../../components/icons/IconLockThin.vue' /* webpackChunkName: "components/icons-icon-lock-thin" */).then(c => wrapFunctional(c.default || c))
export const IconsIconLogout = () => import('../../components/icons/IconLogout.vue' /* webpackChunkName: "components/icons-icon-logout" */).then(c => wrapFunctional(c.default || c))
export const IconsIconMagnifier = () => import('../../components/icons/IconMagnifier.vue' /* webpackChunkName: "components/icons-icon-magnifier" */).then(c => wrapFunctional(c.default || c))
export const IconsIconMagnifierShape = () => import('../../components/icons/IconMagnifierShape.vue' /* webpackChunkName: "components/icons-icon-magnifier-shape" */).then(c => wrapFunctional(c.default || c))
export const IconsIconMap = () => import('../../components/icons/IconMap.vue' /* webpackChunkName: "components/icons-icon-map" */).then(c => wrapFunctional(c.default || c))
export const IconsIconPen = () => import('../../components/icons/IconPen.vue' /* webpackChunkName: "components/icons-icon-pen" */).then(c => wrapFunctional(c.default || c))
export const IconsIconPercent = () => import('../../components/icons/IconPercent.vue' /* webpackChunkName: "components/icons-icon-percent" */).then(c => wrapFunctional(c.default || c))
export const IconsIconPhone = () => import('../../components/icons/IconPhone.vue' /* webpackChunkName: "components/icons-icon-phone" */).then(c => wrapFunctional(c.default || c))
export const IconsIconPin = () => import('../../components/icons/IconPin.vue' /* webpackChunkName: "components/icons-icon-pin" */).then(c => wrapFunctional(c.default || c))
export const IconsIconPinterest = () => import('../../components/icons/IconPinterest.vue' /* webpackChunkName: "components/icons-icon-pinterest" */).then(c => wrapFunctional(c.default || c))
export const IconsIconPrint = () => import('../../components/icons/IconPrint.vue' /* webpackChunkName: "components/icons-icon-print" */).then(c => wrapFunctional(c.default || c))
export const IconsIconQuestion = () => import('../../components/icons/IconQuestion.vue' /* webpackChunkName: "components/icons-icon-question" */).then(c => wrapFunctional(c.default || c))
export const IconsIconReload = () => import('../../components/icons/IconReload.vue' /* webpackChunkName: "components/icons-icon-reload" */).then(c => wrapFunctional(c.default || c))
export const IconsIconReturn = () => import('../../components/icons/IconReturn.vue' /* webpackChunkName: "components/icons-icon-return" */).then(c => wrapFunctional(c.default || c))
export const IconsIconShare = () => import('../../components/icons/IconShare.vue' /* webpackChunkName: "components/icons-icon-share" */).then(c => wrapFunctional(c.default || c))
export const IconsIconStar = () => import('../../components/icons/IconStar.vue' /* webpackChunkName: "components/icons-icon-star" */).then(c => wrapFunctional(c.default || c))
export const IconsIconTag = () => import('../../components/icons/IconTag.vue' /* webpackChunkName: "components/icons-icon-tag" */).then(c => wrapFunctional(c.default || c))
export const IconsIconTicket = () => import('../../components/icons/IconTicket.vue' /* webpackChunkName: "components/icons-icon-ticket" */).then(c => wrapFunctional(c.default || c))
export const IconsIconTooltip = () => import('../../components/icons/IconTooltip.vue' /* webpackChunkName: "components/icons-icon-tooltip" */).then(c => wrapFunctional(c.default || c))
export const IconsIconTwitter = () => import('../../components/icons/IconTwitter.vue' /* webpackChunkName: "components/icons-icon-twitter" */).then(c => wrapFunctional(c.default || c))
export const IconsIconUser = () => import('../../components/icons/IconUser.vue' /* webpackChunkName: "components/icons-icon-user" */).then(c => wrapFunctional(c.default || c))
export const IconsIconYoutube = () => import('../../components/icons/IconYoutube.vue' /* webpackChunkName: "components/icons-icon-youtube" */).then(c => wrapFunctional(c.default || c))
export const AppContactDataForm = () => import('../../components/App/Contact/DataForm.vue' /* webpackChunkName: "components/app-contact-data-form" */).then(c => wrapFunctional(c.default || c))
export const AppContactTextField = () => import('../../components/App/Contact/TextField.vue' /* webpackChunkName: "components/app-contact-text-field" */).then(c => wrapFunctional(c.default || c))
export const AppContactVehicleData = () => import('../../components/App/Contact/VehicleData.vue' /* webpackChunkName: "components/app-contact-vehicle-data" */).then(c => wrapFunctional(c.default || c))
export const AppInvoicesActionButtons = () => import('../../components/App/Invoices/ActionButtons.vue' /* webpackChunkName: "components/app-invoices-action-buttons" */).then(c => wrapFunctional(c.default || c))
export const AppInvoicesCard = () => import('../../components/App/Invoices/Card.vue' /* webpackChunkName: "components/app-invoices-card" */).then(c => wrapFunctional(c.default || c))
export const AppInvoicesList = () => import('../../components/App/Invoices/List.vue' /* webpackChunkName: "components/app-invoices-list" */).then(c => wrapFunctional(c.default || c))
export const AppInvoicesResponsiveCard = () => import('../../components/App/Invoices/ResponsiveCard.vue' /* webpackChunkName: "components/app-invoices-responsive-card" */).then(c => wrapFunctional(c.default || c))
export const AppInvoicesTable = () => import('../../components/App/Invoices/Table.vue' /* webpackChunkName: "components/app-invoices-table" */).then(c => wrapFunctional(c.default || c))
export const AppInvoicesTabs = () => import('../../components/App/Invoices/Tabs.vue' /* webpackChunkName: "components/app-invoices-tabs" */).then(c => wrapFunctional(c.default || c))
export const AppLoginAccountBenefits = () => import('../../components/App/Login/AccountBenefits.vue' /* webpackChunkName: "components/app-login-account-benefits" */).then(c => wrapFunctional(c.default || c))
export const AppLoginForm = () => import('../../components/App/Login/Form.vue' /* webpackChunkName: "components/app-login-form" */).then(c => wrapFunctional(c.default || c))
export const AppOverviewContactDetails = () => import('../../components/App/Overview/ContactDetails.vue' /* webpackChunkName: "components/app-overview-contact-details" */).then(c => wrapFunctional(c.default || c))
export const AppOverviewGreeting = () => import('../../components/App/Overview/Greeting.vue' /* webpackChunkName: "components/app-overview-greeting" */).then(c => wrapFunctional(c.default || c))
export const AppOverviewInvoices = () => import('../../components/App/Overview/Invoices.vue' /* webpackChunkName: "components/app-overview-invoices" */).then(c => wrapFunctional(c.default || c))
export const AppOverviewLastReservation = () => import('../../components/App/Overview/LastReservation.vue' /* webpackChunkName: "components/app-overview-last-reservation" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsCancellationBadge = () => import('../../components/App/Reservations/CancellationBadge.vue' /* webpackChunkName: "components/app-reservations-cancellation-badge" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsCard = () => import('../../components/App/Reservations/Card.vue' /* webpackChunkName: "components/app-reservations-card" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsList = () => import('../../components/App/Reservations/List.vue' /* webpackChunkName: "components/app-reservations-list" */).then(c => wrapFunctional(c.default || c))
export const AppReviewsInviteList = () => import('../../components/App/Reviews/InviteList.vue' /* webpackChunkName: "components/app-reviews-invite-list" */).then(c => wrapFunctional(c.default || c))
export const AppReviewsReviewCard = () => import('../../components/App/Reviews/ReviewCard.vue' /* webpackChunkName: "components/app-reviews-review-card" */).then(c => wrapFunctional(c.default || c))
export const AppReviewsReviewList = () => import('../../components/App/Reviews/ReviewList.vue' /* webpackChunkName: "components/app-reviews-review-list" */).then(c => wrapFunctional(c.default || c))
export const AppReviewsTabs = () => import('../../components/App/Reviews/Tabs.vue' /* webpackChunkName: "components/app-reviews-tabs" */).then(c => wrapFunctional(c.default || c))
export const AppVouchersCreditCard = () => import('../../components/App/Vouchers/CreditCard.vue' /* webpackChunkName: "components/app-vouchers-credit-card" */).then(c => wrapFunctional(c.default || c))
export const AppVouchersCreditList = () => import('../../components/App/Vouchers/CreditList.vue' /* webpackChunkName: "components/app-vouchers-credit-list" */).then(c => wrapFunctional(c.default || c))
export const AppVouchersFriendsBenefitProgram = () => import('../../components/App/Vouchers/FriendsBenefitProgram.vue' /* webpackChunkName: "components/app-vouchers-friends-benefit-program" */).then(c => wrapFunctional(c.default || c))
export const AppVouchersHowItWork = () => import('../../components/App/Vouchers/HowItWork.vue' /* webpackChunkName: "components/app-vouchers-how-it-work" */).then(c => wrapFunctional(c.default || c))
export const AppVouchersShareWithFriend = () => import('../../components/App/Vouchers/ShareWithFriend.vue' /* webpackChunkName: "components/app-vouchers-share-with-friend" */).then(c => wrapFunctional(c.default || c))
export const CoreAlert = () => import('../../components/Core/Alert/Alert.vue' /* webpackChunkName: "components/core-alert" */).then(c => wrapFunctional(c.default || c))
export const CoreBtnFilled = () => import('../../components/Core/Btn/Filled.vue' /* webpackChunkName: "components/core-btn-filled" */).then(c => wrapFunctional(c.default || c))
export const CoreBtnOutlined = () => import('../../components/Core/Btn/Outlined.vue' /* webpackChunkName: "components/core-btn-outlined" */).then(c => wrapFunctional(c.default || c))
export const CoreCheckbox = () => import('../../components/Core/Checkbox/Checkbox.vue' /* webpackChunkName: "components/core-checkbox" */).then(c => wrapFunctional(c.default || c))
export const CoreDatepicker = () => import('../../components/Core/Datepicker/Datepicker.vue' /* webpackChunkName: "components/core-datepicker" */).then(c => wrapFunctional(c.default || c))
export const CoreInputToClipboard = () => import('../../components/Core/InputToClipboard/InputToClipboard.vue' /* webpackChunkName: "components/core-input-to-clipboard" */).then(c => wrapFunctional(c.default || c))
export const CoreMobileInput = () => import('../../components/Core/MobileInput/MobileInput.vue' /* webpackChunkName: "components/core-mobile-input" */).then(c => wrapFunctional(c.default || c))
export const CoreModal = () => import('../../components/Core/Modal/Modal.vue' /* webpackChunkName: "components/core-modal" */).then(c => wrapFunctional(c.default || c))
export const CoreRadioSelect = () => import('../../components/Core/RadioSelect/RadioSelect.vue' /* webpackChunkName: "components/core-radio-select" */).then(c => wrapFunctional(c.default || c))
export const CoreSearchbar = () => import('../../components/Core/Searchbar/Searchbar.vue' /* webpackChunkName: "components/core-searchbar" */).then(c => wrapFunctional(c.default || c))
export const CoreSelect = () => import('../../components/Core/Select/Select.vue' /* webpackChunkName: "components/core-select" */).then(c => wrapFunctional(c.default || c))
export const CoreStarRate = () => import('../../components/Core/StarRate/StarRate.vue' /* webpackChunkName: "components/core-star-rate" */).then(c => wrapFunctional(c.default || c))
export const CoreTextArea = () => import('../../components/Core/TextArea/TextArea.vue' /* webpackChunkName: "components/core-text-area" */).then(c => wrapFunctional(c.default || c))
export const CoreTextField = () => import('../../components/Core/TextField/TextField.vue' /* webpackChunkName: "components/core-text-field" */).then(c => wrapFunctional(c.default || c))
export const CoreTimePicker = () => import('../../components/Core/TimePicker/TimePicker.vue' /* webpackChunkName: "components/core-time-picker" */).then(c => wrapFunctional(c.default || c))
export const CoreTooltip = () => import('../../components/Core/Tooltip/Tooltip.vue' /* webpackChunkName: "components/core-tooltip" */).then(c => wrapFunctional(c.default || c))
export const PagesReservations = () => import('../../components/Pages/Reservations/Index.vue' /* webpackChunkName: "components/pages-reservations" */).then(c => wrapFunctional(c.default || c))
export const PagesReviews = () => import('../../components/Pages/Reviews/Index.vue' /* webpackChunkName: "components/pages-reviews" */).then(c => wrapFunctional(c.default || c))
export const HeaderMetaReviews = () => import('../../components/header/MetaReviews/MetaReviews.vue' /* webpackChunkName: "components/header-meta-reviews" */).then(c => wrapFunctional(c.default || c))
export const HeaderMetaReviewsStars = () => import('../../components/header/MetaReviews/Stars.vue' /* webpackChunkName: "components/header-meta-reviews-stars" */).then(c => wrapFunctional(c.default || c))
export const AppOverviewAdvantages = () => import('../../components/App/Overview/Advantages/Advantages.vue' /* webpackChunkName: "components/app-overview-advantages" */).then(c => wrapFunctional(c.default || c))
export const AppOverviewAdvantagesDiscount = () => import('../../components/App/Overview/Advantages/Discount.vue' /* webpackChunkName: "components/app-overview-advantages-discount" */).then(c => wrapFunctional(c.default || c))
export const AppOverviewAdvantagesNoVouchers = () => import('../../components/App/Overview/Advantages/NoVouchers.vue' /* webpackChunkName: "components/app-overview-advantages-no-vouchers" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsDetailsPageAlert = () => import('../../components/App/Reservations/DetailsPage/Alert.vue' /* webpackChunkName: "components/app-reservations-details-page-alert" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsDetailsPageCosts = () => import('../../components/App/Reservations/DetailsPage/Costs.vue' /* webpackChunkName: "components/app-reservations-details-page-costs" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsDetailsCard = () => import('../../components/App/Reservations/DetailsPage/DetailsCard.vue' /* webpackChunkName: "components/app-reservations-details-card" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsDetailsSection = () => import('../../components/App/Reservations/DetailsPage/DetailsSection.vue' /* webpackChunkName: "components/app-reservations-details-section" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsDetailsPageReservation = () => import('../../components/App/Reservations/DetailsPage/Reservation.vue' /* webpackChunkName: "components/app-reservations-details-page-reservation" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsDetailsPageReservationDropdown = () => import('../../components/App/Reservations/DetailsPage/ReservationDropdown.vue' /* webpackChunkName: "components/app-reservations-details-page-reservation-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsEditCheckReservation = () => import('../../components/App/Reservations/Edit/CheckReservation.vue' /* webpackChunkName: "components/app-reservations-edit-check-reservation" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsEditExtraProducts = () => import('../../components/App/Reservations/Edit/ExtraProducts.vue' /* webpackChunkName: "components/app-reservations-edit-extra-products" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsEditPersonalInfo = () => import('../../components/App/Reservations/Edit/PersonalInfo.vue' /* webpackChunkName: "components/app-reservations-edit-personal-info" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsEditReceipt = () => import('../../components/App/Reservations/Edit/Receipt.vue' /* webpackChunkName: "components/app-reservations-edit-receipt" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsEditReservation = () => import('../../components/App/Reservations/Edit/Reservation.vue' /* webpackChunkName: "components/app-reservations-edit-reservation" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsEditReservationDropdown = () => import('../../components/App/Reservations/Edit/ReservationDropdown.vue' /* webpackChunkName: "components/app-reservations-edit-reservation-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsEditTermsAndConditions = () => import('../../components/App/Reservations/Edit/TermsAndConditions.vue' /* webpackChunkName: "components/app-reservations-edit-terms-and-conditions" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsEditTravelDetail = () => import('../../components/App/Reservations/Edit/TravelDetail.vue' /* webpackChunkName: "components/app-reservations-edit-travel-detail" */).then(c => wrapFunctional(c.default || c))
export const AppReservationsEditTravelInfo = () => import('../../components/App/Reservations/Edit/TravelInfo.vue' /* webpackChunkName: "components/app-reservations-edit-travel-info" */).then(c => wrapFunctional(c.default || c))
export const AppReviewsIdCard = () => import('../../components/App/Reviews/Id/Card.vue' /* webpackChunkName: "components/app-reviews-id-card" */).then(c => wrapFunctional(c.default || c))
export const AppReviewsIdRadioSelect = () => import('../../components/App/Reviews/Id/RadioSelect.vue' /* webpackChunkName: "components/app-reviews-id-radio-select" */).then(c => wrapFunctional(c.default || c))
export const AppReviewsIdRateParkos = () => import('../../components/App/Reviews/Id/RateParkos.vue' /* webpackChunkName: "components/app-reviews-id-rate-parkos" */).then(c => wrapFunctional(c.default || c))
export const AppReviewsIdReservation = () => import('../../components/App/Reviews/Id/Reservation.vue' /* webpackChunkName: "components/app-reviews-id-reservation" */).then(c => wrapFunctional(c.default || c))
export const AppReviewsIdReservationDropdown = () => import('../../components/App/Reviews/Id/ReservationDropdown.vue' /* webpackChunkName: "components/app-reviews-id-reservation-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AppReviewsIdYourDetails = () => import('../../components/App/Reviews/Id/YourDetails.vue' /* webpackChunkName: "components/app-reviews-id-your-details" */).then(c => wrapFunctional(c.default || c))
export const AppReviewsIdYourExperience = () => import('../../components/App/Reviews/Id/YourExperience.vue' /* webpackChunkName: "components/app-reviews-id-your-experience" */).then(c => wrapFunctional(c.default || c))
export const PagesReservationsId = () => import('../../components/Pages/Reservations/Id/Index.vue' /* webpackChunkName: "components/pages-reservations-id" */).then(c => wrapFunctional(c.default || c))
export const PagesReviewsId = () => import('../../components/Pages/Reviews/Id/Index.vue' /* webpackChunkName: "components/pages-reviews-id" */).then(c => wrapFunctional(c.default || c))
export const PagesReviewsIdThanks = () => import('../../components/Pages/Reviews/Id/Thanks.vue' /* webpackChunkName: "components/pages-reviews-id-thanks" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
